import moment from "moment-timezone";

export const currencyFormate = (value) => {
  const safeValue = value !== undefined && value !== null ? value : 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseInt(safeValue));
};

export function getDateFromTimestamp(timestamp, formate = "MM/DD/YYYY") {
  if (parseInt(timestamp) === 0) {
    return "--/--/----";
  }
  // Automatically detect the user's timezone
  const userTimeZone = moment.tz.guess();

  // Convert the UTC timestamp (in milliseconds) to the user's local time zone
  return moment.utc(+timestamp).tz(userTimeZone).format(formate);
}

export function throttle(callback, delay = 1000) {
  let shouldWait = false;

  return (...args) => {
    if (shouldWait) return;
    callback(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
}

export const formatTimestamp = (
  timestamp,
  formate = "MM/DD/YYYY - HH:mm:ss z"
) => {
  if (parseInt(timestamp) === 0) {
    return "--/--/----";
  }

  const userTimeZone = moment.tz.guess();

  return moment(timestamp).tz(userTimeZone).format(formate);
};

export const getDayName = (timestamp) => {
  if (parseInt(timestamp) === 0) {
    return "";
  }

  const userTimeZone = moment.tz.guess();

  const date = moment(+timestamp).tz(userTimeZone);

  return date.format("dddd");
};
